<template>
	<div class="bg-secondary d-flex align-items-center justify-content-between p-3 mb-4 br-10 max-1085" v-if="Object.keys(store.customerDetail).length && 
    (Object.keys(store.customerDetail).length && store.customerDetail.integratedBrokerStatus && store.customerDetail.integratedBrokerStatus.length && !store.customerDetail.integratedBrokerStatus[0].completed) || 
    (Object.keys(store.customerDetail).length && store.customerDetail.requiredActions && store.customerDetail.requiredActions.length)">
        <div class="d-block" v-if="!store.customerDetail?.completed">
           <h5 class="text-white mb-1">{{$t('upgradeHeader.upgradeHeadercontent1')}}</h5>
           <p class="text-white mb-0">{{$t('upgradeHeader.upgradeHeadercontent2')}} </p>
        </div>
        <div class="d-block" v-if="store.customerDetail?.completed && store.customerDetail?.requiredActions && store.customerDetail?.requiredActions.length ">
            <h5 class="text-white mb-1">{{$t('upgradeHeader.upgradeHeadercontent4')}}</h5>
            <p class="text-white mb-0">{{$t('upgradeHeader.upgradeHeadercontent5')}} </p>
         </div>
         <div class="d-block" v-if="store.customerDetail?.completed && !store.customerDetail?.requiredActions && store.customerDetail?.integratedBrokerStatus && !store.customerDetail?.integratedBrokerStatus?.[0]?.completed">
            <h5 class="text-white mb-1">{{$t('upgradeHeader.upgradeHeadercontent6')}}</h5>
            <p class="text-white mb-0">{{$t('upgradeHeader.upgradeHeadercontent7')}} </p>
         </div>
        <div class="d-block">
            <a class="button white_btn bg-white rounded border-button secondary" 
            :href="store.customerDetail.completed ? store.customerDetail.requiredActions && store.customerDetail.requiredActions.length ? (!store.questionariesStatus?.canSubmit) ? `${static_vars.domainURL}register/test-result?type=success` : static_vars.domainURL+'register/sutaibility' : (store.customerDetail.integratedBrokerStatus && store.customerDetail.integratedBrokerStatus.length ? static_vars.domainURL+'register/select-a-broker' : (static_vars.domainURL+'register/add-trading-account')) : static_vars.domainURL+'register/personal-details'" :class="{'disabled' : store.customerDetail?.readOnly}" v-if="store.customerDetail?.completed">{{$t('upgradeHeader.upgradeHeadercontent8')}}
           </a>
           <router-link to="/dashboard/personal-info" class="button white_btn bg-white rounded border-button secondary"  v-else>{{$t('upgradeHeader.upgradeHeadercontent3')}}</router-link>
        </div>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
     setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
            };
        },
    }
</script>