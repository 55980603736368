<template>
    <div class="col-12 collg-9 max-1085" v-if="Object.keys(store.userDetail).length && store.userDetail.registrationOrigin=='MIGRATION'">
        <Upgrade />
        <h3 class="medium mb-3 f-28 darkblueColor">{{$t('subscription_plan.subscriptiontitle')}} </h3>
        <div class="copytradeTitle mb-4"> 
            <div class="cardBorder d-flex flex-wrap p-3 br-12 blockMob justify-content-between align-items-start bg-card mb-2"> 
                <div class="activePlan">
                    <div class="d-flex align-items-center">
                        <h3 class="mb-2 medium d-inline-block darkblueColor f-18">{{$t('subscription_plan.subscriptioncontent1')}} </h3>
                    </div>
                    <p class="neutralGrey f-14 mb-2" v-html="$t('subscription_plan.subscriptioncontent2')"> </p>
                    <p class="neutralGrey f-14 mb-2" v-html="$t('subscription_plan.subscriptioncontent3')"></p>
                    <p class="neutralGrey f-14">{{$t('subscription_plan.subscriptioncontent4')}} </p>
                    <a href="/leaders" class="button fillBtn zulu_btn">{{$t('subscription_plan.subscriptioncontent5')}} </a>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-9 max-1085" v-else>
        <template v-if="!becomeLeaderMsg()">
            <div class="alertSubscription d-flex align-items-center">
                <p class="mb-0 d-flex align-items-center"><vue-feather size="22" class="flex-shrink-0 me-3 secondary" type="alert-triangle"></vue-feather> {{$t('subscription_plan.text1')}}</p>
                <!-- <a :href="static_vars.domainURL+'register/add-trading-account'" :class="{'disabled' : store.customerDetail?.readOnly}" class="medium flex-shrink-0 button zulu_btn fillBtn rounded ms-2">{{$t('subscription_plan.text2')}}</a> -->
            </div>
        </template>
        <template v-else>
            <p class="alertSubscription d-flex align-items-center" v-if="store.userSelectedAccount?.isLeader"><vue-feather size="22" class="me-2 secondary" type="alert-triangle"></vue-feather> {{$t('subscription_plan.text3')}}</p>
            <div class="" v-if="store.subscriptionSelectPlanUpdated?.length && !isChange">
                <Upgrade />
                <h3 class="medium mb-3 f-28 darkblueColor">{{$t('subscription_plan.subscriptiontitle')}} </h3>
                <div v-for="item,key in store.subscriptionSelectPlanUpdated" :key="key">
                    <div class="copytradeTitle mb-4" v-if="item.status=='ACTIVE' && item.type != 'FREE_PLAN'"> 
                        <div class="cardBorder d-flex flex-wrap p-3 br-12 blockMob justify-content-between align-items-center bg-card" v-if="!item.state || item.state=='NORMAL'"> 
                            <div class="activePlan">
                                <div class="d-flex align-items-center">
                                    <h3 class="mb-0 medium d-inline-block darkblueColor f-18">{{item.totalSlotsSubscribed || ''}} {{$t('subscription_plan.subscriptioncontent7')}} </h3>
                                    <p class="d-inline-block menutext f-18 midgray ms-2 mb-0">&#x2022; {{item.months || ''}} {{$t('subscription_plan.subscriptioncontent8')}}</p>
                                </div>
                                <div class="willPlan">
                                    <p class="neutralGrey mb-0 f-15"><span>{{$t('subscription_plan.subscriptioncontent9')}}</span> <b> {{expireDate(item.nextBillingDate || '')}}</b></p>
                                </div>
                            </div>
                            <div class="flex-shrink-0 text-end">
                                <h3 class="mb-1 f-20 bold  darkblueColor">${{(parseFloat(item.monthlyPaidAmount * item.months) || 0).toFixed(2)}}</h3>
                                <div class="d-flex align-items-center">
                                    <button :class="{'disabled' : store.customerDetail?.readOnly}" class="button secondary zulu_btn rounded border-button small me-1" @click="isChange = true;step=1">{{$t('subscription_plan.subscriptioncontent10')}} </button>
                                    <button :class="{'disabled' : store.customerDetail?.readOnly}" class="button fillBtn zulu_btn small" @click="confirmCancelSub=true">{{$t('subscription_plan.subscriptioncontent11')}} </button>
                                </div>
                            </div>
                        </div>
                        <div class="cardBorder d-flex flex-wrap p-3 br-12 blockMob justify-content-between align-items-center bg-card" v-else-if="item.state=='PENDING_CHANGE'"> 
                            <div class="activePlan">
                                <div class="d-flex align-items-center">
                                    <h3 class="mb-0 medium d-inline-block darkblueColor f-18">{{item.totalSlotsSubscribed || ''}} {{$t('subscription_plan.subscriptioncontent12')}} </h3>
                                    <p class="d-inline-block menutext f-18 midgray ms-2 mb-0">&#x2022; {{item.updatedMonths || ''}} {{$t('subscription_plan.subscriptioncontent13')}} </p>
                                </div>
                                <div class="willPlan">
                                    <p class="neutralGrey mb-0 f-15"> <b> {{expireDate(item.nextBillingDate || '')}}</b></p>
                                </div>
                            </div>
                            <div class="flex-shrink-0 text-end">
                                <h3 class="mb-1 f-20 bold  darkblueColor">${{(parseFloat(item.updatedMonthlyPaidAmount * item.updatedMonths) || 0).toFixed(2)}}</h3>
                                <div class="d-flex align-items-center">
                                    <button :class="{'disabled' : store.customerDetail?.readOnly}" class="button secondary zulu_btn rounded border-button small me-1" @click="isChange = true;step=1">{{$t('subscription_plan.subscriptioncontent10')}} </button>
                                    <button :class="{'disabled' : store.customerDetail?.readOnly}" class="button fillBtn zulu_btn small" @click="confirmCancelSub=true">{{$t('subscription_plan.subscriptioncontent11')}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="cardBorder d-flex flex-wrap p-3 br-12 blockMob justify-content-between align-items-center bg-card" v-else-if="item.state=='PENDING_DOWNGRADE'"> 
                            <div class="activePlan">
                                <div class="d-flex align-items-center">
                                    <h3 class="mb-0 medium d-inline-block darkblueColor f-18">{{item.totalSlotsSubscribed || ''}} {{$t('subscription_plan.subscriptioncontent12')}} </h3>
                                    <p class="d-inline-block menutext f-18 midgray ms-2 mb-0">&#x2022; {{item.updatedMonths || ''}} {{$t('subscription_plan.subscriptioncontent13')}}</p>
                                </div>
                                <div class="willPlan">
                                    <p class="neutralGrey mb-0 f-15"><span>{{$t('subscription_plan.subscriptioncontent14')}} </span> <b> {{expireDate(item.nextBillingDate || '')}}</b></p>
                                </div>
                            </div>
                            <div class="flex-shrink-0 text-end">
                                <h3 class="mb-1 f-20 bold  darkblueColor">${{(parseFloat(item.updatedMonthlyPaidAmount * item.updatedMonths) || 0).toFixed(2)}}</h3>
                                <div class="d-flex align-items-center">
                                    <button :class="{'disabled' : store.customerDetail?.readOnly}" class="button secondary zulu_btn rounded border-button small me-1" @click="isChange = true;step=1">{{$t('subscription_plan.subscriptioncontent10')}}</button>
                                    <button :class="{'disabled' : store.customerDetail?.readOnly}" class="button fillBtn zulu_btn small" @click="confirmCancelSub=true">{{$t('subscription_plan.subscriptioncontent11')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="copytradeTitle mb-4" v-if="item.status=='PENDING_CANCELATION'"> 
                        <div class="d-flex align-items-center justify-content-between mb-2">
                        </div>
                        <div class="cardBorder d-flex flex-wrap p-3 br-12 blockMob justify-content-between align-items-center bg-card">  
                            <div class="activePlan">
                                <div class="d-flex align-items-center">
                                    <h3 class="mb-0 medium d-inline-block darkblueColor f-18">{{item.totalSlotsSubscribed || ''}} {{$t('subscription_plan.subscriptioncontent12')}}</h3>
                                    <p class="d-inline-block menutext f-18 midgray ms-2 mb-0">&#x2022; {{item.months || ''}} {{$t('subscription_plan.subscriptioncontent13')}}</p>
                                </div>
                                <div class="willPlan">
                                    <p class="neutralGrey mb-0 f-15"><span>{{$t('subscription_plan.subscriptioncontent15')}}</span> <b> {{expireDate(item.expirationDate || '')}}</b></p>
                                </div>
                            </div>
                            <div class="flex-shrink-0 text-end">
                                <h3 class="mb-1 f-20 bold  darkblueColor">${{(parseFloat((item.monthlyPaidAmount || 0) * (item.months || 0))).toFixed(2)}}</h3>
                                <!-- <div class="d-flex align-items-center">
                                    <button class="button fillBtn zulu_btn small" @click="isChange = true;step=1">Activate</button>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="copytradeTitle mb-4" v-if="item.status == 'FREE_PLAN' && item.unlimitedSlots && item.brokerName">
                        <div class="d-flex align-items-center justify-content-between mb-2">
                        </div>
                        <div class="cardBorder d-flex flex-wrap p-3 br-12 blockMob justify-content-between align-items-center bg-card">  
                            <div class="activePlan">
                                <div class="d-flex align-items-center">
                                    <h3 class="mb-0 medium d-inline-block darkblueColor f-18">{{item.unlimitedSlots ? item.description : item.totalSlotsSubscribed || ''}} {{item.unlimitedSlots ? '' : $t('subscription_plan.subscriptioncontent12')}}</h3>
                                    <p class="d-inline-block menutext f-18 midgray ms-2 mb-0" v-if="!item.unlimitedSlots">&#x2022; {{item.months || ''}} {{$t('subscription_plan.subscriptioncontent13')}}</p>
                                </div>
                                <div class="willPlan">
                                    <p class="neutralGrey mb-0 f-15" v-if="!item.unlimitedSlots"><span>{{$t('subscription_plan.subscriptioncontent15')}}</span> <b> {{expireDate(item.expirationDate || '')}}</b></p>
                                    <p class="neutralGrey mb-0 f-15" v-else><span>{{$t('subscription_plan.subscriptioncontent55')}}</span></p>
                                </div>
                            </div>
                            <div class="flex-shrink-0 text-end">
                                <h3 class="mb-1 f-20 bold  darkblueColor">{{$t('subscription_plan.subscriptioncontent53')}}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="copytradeTitle mb-4" v-if="item.status == 'ACTIVE' && item.type == 'FREE_PLAN'"> 
                        <div class="cardBorder d-flex flex-wrap p-3 br-12 blockMob justify-content-between align-items-center bg-card"> 
                            <div class="activePlan">
                                <div class="d-flex align-items-center">
                                    <h3 class="mb-0 medium d-inline-block darkblueColor f-18">{{item.totalSlotsSubscribed || ''}} {{$t('subscription_plan.subscriptioncontent7')}} </h3>
                                    <p class="d-inline-block menutext f-18 midgray ms-2 mb-0">&#x2022; {{item.brokerName || ''}}</p>
                                </div>
                                <div class="willPlan">
                                    <p class="neutralGrey mb-0 f-15"><span>{{daysRemaining(item.expirationDate || '')}} {{$t('subscription_plan.subscriptioncontent54')}}</span></p>
                                </div>
                            </div>
                            <div class="flex-shrink-0 text-end">
                                <h3 class="mb-1 f-20 bold  darkblueColor">{{$t('subscription_plan.subscriptioncontent53')}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bordergS bg-white br-20 mt-38 p-0" v-if="planChooseORnot() && !filterStatus() ">
                    <!-- && !filterStatus() -->
                    <a href="javascript:void(0);" :class="{'disabled disable-bg' : store.customerDetail?.readOnly}" class="d-flex align-items-center justify-content-between px-4 py-3" @click="step=1;isChange=true">
                        <div class="d-flex align-items-center">
                            <div class="me-2 payMode">
                                <v-lazy-image class="d-block" width="22" height="22" src="/assets/images/choose-plan.svg" :alt="$t('subscription_plan.subscriptioncontent56')" />
                            </div>
                            <div class="d-block">
                                <h6 class="mb-0 darkblueColor f-17">{{$t('subscription_plan.subscriptioncontent64')}} </h6>
                            </div>
                        </div>
                        <div class="d-block whiteMode line-1">
                            <vue-feather size="15" type="chevron-right"></vue-feather>
                        </div>
                    </a>
                </div>
                <div class="bordergS bg-white br-20 mt-38 p-0" :class="{'disabled disable-bg' : store.customerDetail?.readOnly}">
                    <!-- v-if="store.subscriptionCards && store.subscriptionCards.length" -->
                    <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between px-4 py-3" @click="step=3;isChange=true;payment=''">
                        <div class="d-flex align-items-center">
                            <div class="me-2 payMode">
                                <v-lazy-image class="d-block" width="22" height="22" src="/assets/images/paymentMethod.svg" :alt="$t('subscription_plan.subscriptioncontent56')" />
                            </div>
                            <div class="d-block">
                                <h6 class="mb-0 darkblueColor f-17">{{$t('subscription_plan.subscriptioncontent16')}} </h6>
                            </div>
                        </div>
                        <div class="d-block whiteMode line-1">
                            <vue-feather size="15" type="chevron-right"></vue-feather>
                        </div>
                    </a>
                </div>
            </div>
            <div class="" v-else>
                <div v-if="step==1">
                    <button class="button fillBtn small" v-if="store.subscriptionSelectPlanUpdated?.length" @click="isChange=false;payment='';selectedPlanStep=[]">{{$t('subscription_plan.subscriptioncontent17')}} </button>
                    <div class="row">
                        <div class="col-12 col-md-8">
                            <h3 class="medium f-28 darkblueColor mt-3 mb-1">{{$t('subscription_plan.subscriptioncontent18')}}</h3>
                            <p class="mb-0 gray f-15 mb-3">{{$t('subscription_plan.subscriptioncontent19')}} </p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-md-5">
                        <div class="me-5">
                            <h6 class="mb-0 darkblueColor f-18">{{$t('subscription_plan.subscriptioncontent20')}} </h6>
                            <!-- <a class="secondary text-decoration-underline medium" href="javascript:void(0)" @click="copyTradeChange=copyTrade">Change</a> -->
                        </div>
                        <div :title="$t('subscription_plan.subscriptioncontent39')" class="bordergS regular f-26 d-flex p-3 br-10 gray lightblueBG"><input type="number" class="selectnumber" v-model="copyTrade" @input="copyTradeChange=copyTrade"/></div>
                    </div>
                    <h6 class="mt-5 darkblueColor f-18">{{$t('subscription_plan.subscriptioncontent21')}} </h6>
                    <div class="row gy-3 align-items-end">
                        <div class="col-12">
                            <div class="mt-0 plan_box_main br-14" :class="[{'active':plan.name=='Unlimited Plan'}]" v-for="plan,key in store.subscriptionPlans" :key="key">
                                <!-- <h6 class="text-uppercase text-white f-12 my-1 medium text-center" v-if="plan.name=='Unlimited Plan'">Popular Plan</h6> -->
                                <div class="cutomChechBox radio bg-white br-12 w-100 text-center py-2 pe-3 plan_box position-relative bordergS" :class="[{'active':selectedPlanStep==plan}]">
                                    <div class="planEd flex-between flex-wrap w-100">
                                        <!-- <h3 class="planTitle bold">{{plan.name || ''}}</h3> -->
                                        <h5 class="mb-0 d-flex align-items-center">{{plan.durationInMonths || ''}} <span class="ms-1">{{$t('subscription_plan.subscriptioncontent22')}} </span></h5>
                                        <div class="d-flex align-items-start mobDesign">
                                            <p class="f-14 midgray mb-0 me-2">${{(parseFloat(plan.pricePerSlot) || 0).toFixed(2)}} {{$t('subscription_plan.subscriptioncontent23')}}</p>
                                            <h5 class="semibold minAdded mb-0">
                                                ${{(copyTradeChange>1 ? 
                                                    (parseInt(copyTradeChange)*parseFloat(plan.pricePerSlot || 0)*(plan.durationInMonths || 0)) :
                                                    (parseFloat(plan.pricePerSlot || 0) * (plan.durationInMonths || 0))).toFixed(2)}}  
                                                <span class="secondary d-flex regular text-uppercase f-12 mb-0" v-if="plan.discountPercentage">{{$t('subscription_plan.subscriptioncontent24')}}  {{plan.discountPercentage}}%</span></h5>
                                        
                                        </div>
                                    </div>
                                    <input type="radio" name="radio" @click="copyTradeChange=copyTrade;selectedPlanStep=plan;payment=copyTradeChange*(plan.pricePerSlot || 0)*(plan.durationInMonths || 0)"/>
                                    <span class="checkmark"></span>
                                </div>
                            </div>
                            <div class="planEo mt-4">
                                <a href="javascript:void(0);" class="button fillBtn zulu_btn rounded py-md-4 px-md-5" :class="[{'disabled':!payment},{'disabled disable-bg' : store.customerDetail?.readOnly}]" @click="step=2;;selectPlan(selectedPlanStep)">{{$t('subscription_plan.subscriptioncontent25')}} </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div v-if="step==2">
                            <button class="button fillBtn small" @click="step=1;payment='';selectedPlanStep=[]">{{$t('subscription_plan.subscriptioncontent17')}}</button>
                            <div class="bordergS bg-white br-12 mb-4 p-md-4 p-2 mt-4" v-if="activeStatus()">
                                <div v-if="!store.subscriptionSelected.state || store.subscriptionSelected.state=='NORMAL'" >
                                    <b>{{$t('subscription_plan.subscriptioncontent26')}} </b> <span> {{$t('subscription_plan.subscriptioncontent27')}} </span> <b>{{expireDate(store.subscriptionSelected.nextBillingDate || '')}}</b>, <span>{{$t('subscription_plan.subscriptioncontent28')}} </span> <b>${{(parseFloat((store.subscriptionSelected.monthlyPaidAmount || '') * (store.subscriptionSelected.months || ''))).toFixed(2)}}</b> <span>{{$t('subscription_plan.subscriptioncontent29')}} </span> <b>{{store.subscriptionSelected.months || ''}}</b> {{$t('subscription_plan.subscriptioncontent30')}}
                                </div>
                                <div v-else-if="store.subscriptionSelected.state=='PENDING_CHANGE'">
                                    <b>{{$t('subscription_plan.subscriptioncontent31')}} </b> <span>{{$t('subscription_plan.subscriptioncontent32')}}</span> <b>{{expireDate(store.subscriptionSelected.nextBillingDate || '')}}</b>, <span>{{$t('subscription_plan.subscriptioncontent33')}}</span> <b>${{(parseFloat((store.subscriptionSelected.updatedMonthlyPaidAmount || '') * (store.subscriptionSelected.updatedMonths || ''))).toFixed(2)}}</b> <span>{{$t('subscription_plan.subscriptioncontent34')}}</span> <b>{{store.subscriptionSelected.updatedMonths || ''}}</b> <span> {{$t('subscription_plan.subscriptioncontent35')}} </span>
                                </div>
                                <div v-else-if="store.subscriptionSelected.state=='PENDING_DOWNGRADE'">
                                    <span>{{$t('subscription_plan.subscriptioncontent36')}}</span> <b>{{store.subscriptionSelected.updatedSlots || ''}}</b> <span>{{$t('subscription_plan.subscriptioncontent37')}}</span> <b>{{expireDate(store.subscriptionSelected.nextBillingDate || '')}}</b> <span>{{$t('subscription_plan.subscriptioncontent38')}}</span> <b>{{store.subscriptionSelected.updatedMonths || ''}}</b> <span>{{$t('subscription_plan.subscriptioncontent9')}}</span> <b>{{store.subscriptionSelected.totalSlotsSubscribed || ''}}</b> <span> {{$t('subscription_plan.subscriptioncontent40')}}</span>
                                </div>
                            </div>
                            <div v-else>
                                <h6 class="mt-4 py-3 d-flex align-items-center darkblueColor f-18">
                                    <span class="me-1 payMode"><v-lazy-image width="20" height="20" src="/assets/images/agreement.png" :alt="$t('subscription_plan.subscriptioncontent57')"/></span> {{$t('subscription_plan.subscriptioncontent57')}}</h6>
                                <div class="bordergS bg-white br-12 mb-4 p-md-4 p-2">
                                    <div class="d-flex align-items-center flex-wrap justify-content-between mb-3">
                                        <div class="Earned">
                                            <h6 class="mb-0 darkblueColor f-18 semibold">{{selectedPlanStep.durationInMonths}} {{$t('subscription_plan.subscriptioncontent41')}} </h6>
                                            <p class="mb-0 gray f-14 medium">{{$t('subscription_plan.subscriptioncontent58')}} {{copyTradeChange}} {{$t('subscription_plan.subscriptioncontent42')}} </p>
                                        </div>
                                        <div class="Earned text-end">
                                            <h6 class="mb-0 darkblueColor f-18 semibold">${{(selectedPlanStep.pricePerSlot || 0).toFixed(2)}}{{$t('subscription_plan.subscriptioncontent43')}} </h6>
                                            <p class="mb-0 green f-14 medium" v-if="selectedPlanStep.discountPercentage">{{selectedPlanStep.discountPercentage}}% {{$t('subscription_plan.subscriptioncontent44')}}</p>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center flex-wrap justify-content-between mb-3">
                                        <h6 class="mb-0 darkblueColor f-16 semibold">{{$t('subscription_plan.subscriptioncontent45')}} </h6>
                                        <h6 class="mb-0 darkblueColor f-18 semibold">${{(payment || 0).toFixed(2)}}</h6>
                                    </div>
                                    <div class="py-0 bordergST">
                                        <div class="d-flex justify-content-between mt-2 pt-2">
                                            <h6 class="mb-0 darkblueColor f-18 semibold">{{$t('subscription_plan.subscriptioncontent46')}} </h6>
                                            <h6 class="mb-0 darkblueColor f-20 semibold dashgreenColor">${{(payment || 0).toFixed(2)}}</h6>
                                        </div>
                                        <div class="alertContent">
                                            <p class="mb-0 gray f-14">{{$t('subscription_plan.subscriptioncontent47')}} </p>
                                        </div>
                                        <div class="d-flex justify-content-end mt-2 pt-2">
                                            <button class="button fillBtn" @click="step=3">{{$t('subscription_plan.subscriptioncontent48')}} </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="bordergS bg-white br-20 mt-38 p-0">
                            <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between px-4 py-3 bordergSB">
                                <div class="d-flex align-items-center">
                                    <div class="me-2"><v-lazy-image width="22" height="22" src="/assets/images/upgradePlan.svg" alt="Upgrade" /></div>
                                    <div class="d-block">
                                        <h6 class="mb-0 darkblueColor f-17">Change / Upgrade Plan</h6>
                                        <p class="medium mb-0 me-4 darkblueColor"><span class="gray regular">Your next payment is on</span> Sept. 26, 2022</p>
                                    </div>
                                </div>
                                <div class="d-block">
                                    <button class="button fillBtn small">Upgrade Plan</button>
                                </div>
                            </a>
                        </div> -->
                        <div v-if="step==3">
                            <button class="button fillBtn small" @click="step=2" v-if="payment">{{$t('subscription_plan.subscriptioncontent49')}} </button>
                            <button class="button fillBtn small" @click="isChange=false" v-else>{{$t('subscription_plan.subscriptioncontent49')}} </button>
                            <div class="cardPamentMethod">
                                <CardPayment :payment="payment" :selectedPlanStep="selectedPlanStep" :copyTrade="copyTrade"></CardPayment>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
    <!-- :class="confirmCancelSub?'show fade':'d-none'" -->
    <div class="modal show fade" style="display: block;" v-if="confirmCancelSub">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">{{$t('subscription_plan.subscriptioncontent50')}} </h5>
                    <button type="button" class="btn-close" @click="confirmCancelSub=false"></button>
                </div>
                <div class="modal-body p-4 border-bottom text-center">
                    <!-- <v-lazy-image height="250" width="250" class="d-block mx-auto mb-4" src="/assets/images/card-icons/congratulationAdded.gif" alt="Payment Method" /> -->
                    <p class="mb-0">{{$t('subscription_plan.subscriptioncontent51')}}</p>
                </div>
                <div class="modal-footer border-0 justify-content-end">
                    <a href="javascript:void(0)" class="button border-button rounded border-button zulu_btn d-flex" @click="confirmCancelSub=false">{{$t('subscription_plan.subscriptioncontent49')}}</a>
                    <a href="javascript:void(0)" class="button fillBtn zulu_btn d-flex" @click="cancelSubscription()">{{$t('subscription_plan.subscriptioncontent52')}} </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import CardPayment from "@/views/dashboard-new/pages/payment.vue";
    import moment from "moment";
    import Upgrade from './upgrade-header'
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        components: { CardPayment ,Upgrade},
        data() {
            return {
                step: 1,
                payment: '',
                copyTrade: 1,
                copyTradeChange: 1,
                isChange: false,
                confirmCancelSub: false,
                // setCurrentStatus: '',
                selectedPlanStep: [],
                isShowPlan: false,
                status: ['ACTIVE','PENDING_CANCELATION'],
            };
        },
        methods:{
            expireDate(date){
                return moment(date).format('MMM. DD, YYYY')
            },
            daysRemaining(date){
                let today = moment()
                let expire = moment(date)
                return expire.diff(today,'days')
            },
            selectPlan(pack){
                if(pack){
                    this.store.subscriptionSelectPlan({
                        "membershipPlanId": parseInt(pack.id),
                        "slots": this.copyTrade
                    },false)
                }
            },
            cancelSubscription(){
                this.store.subscriptionCancelPlan({},false).then(()=>{
                    this.store.subscriptionSelectPlanUpdate({},false)
                    this.confirmCancelSub=false
                })
            },
            filterStatus(){
                if(this.store.subscriptionSelectPlanUpdated?.length){
                    var finalst = this.store.subscriptionSelectPlanUpdated.filter((val)=> (val.status == 'ACTIVE' && val.type == 'MAIN') || (val.status == 'PENDING_CANCELATION' && val.type == 'MAIN'))
                    if(finalst?.length){ return true } else { return false }
                }
            },
            activeStatus(){
                if(this.store.subscriptionSelectPlanUpdated?.length){
                    let finalActive = this.store.subscriptionSelectPlanUpdated.filter((val)=>val.status == 'ACTIVE' && val.type == 'MAIN')
                    if(finalActive?.length){return true} else {return false}
                }
            },
            planChooseORnot(){
                if(this.store.getConnectedAccounts?.length && Object.keys(this.store.customerDetail).length){
                    let liveAccounts = Object.values(this.store.customerDetail.accounts).filter(val=> !val.demo)
                    var choosePlan = false
                    for(let account of liveAccounts){
                        let matchAccount = this.store.getConnectedAccounts.find(item=> item.name == account.brokerName)
                        if(matchAccount && matchAccount?.integrationMode != 'INTEGRATED' && matchAccount?.integrationMode != 'CO_BRANDED') {
                            choosePlan = true
                            break;
                        } 
                        if(!matchAccount){
                            choosePlan = true
                        }
                    } return choosePlan
                } else { return false }
            },
            becomeLeaderMsg(){
                if(this.store.userTradingAccountsList?.length){
                    let liveAcc = this.store.userTradingAccountsList.filter(a=> a.demo == false && a.isLeader == false)
                    if(liveAcc.length){ return true }
                    return false
                }
            }
        },
        created() {
            this.store.subscriptionPlansList({},true)
            this.store.getUserDeatilFromCheckout({},false).then(resp =>{
                if(Object.keys(resp).length && !resp.id){
                    this.store.subscriptionSavedCheckout({},false)
                }
            })
            this.store.subscriptionSelectPlanUpdate({},false).then((res)=>{
                if(res && this.store.subscriptionSelectPlanUpdated?.length){
                    let previousSlot =  this.store.subscriptionSelectPlanUpdated.filter((val)=>val.status == 'ACTIVE')
                    if(previousSlot && previousSlot.totalSlotsSubscribed){
                        this.copyTrade = previousSlot.totalSlotsSubscribed
                        this.copyTradeChange = this.copyTrade
                    }
                }
            })
            this.store.subscriptionCardList({},true)
            this.store.getConnectedAccountsList({},false) 
            this.becomeLeaderMsg()
        },
    };
</script>
<style scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>