<template>
    <div class="position-relative dorpDown">
        <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between mt-4 py-3">
            <div class="d-flex align-items-center">
                <div class="payMode me-1"><v-lazy-image class="d-block" width="22" height="22" src="/assets/images/paymentMethod.svg" :alt="$t('subscription_plan.subscriptioncontent63')" /></div>
                <h6 class="mb-0 darkblueColor f-18">{{$t('paymentMethod.paymentMethodcontent1')}}</h6>
            </div>
            <div class="d-block">
                <!-- <vue-feather type="chevron-right" size="16"></vue-feather> -->
            </div>
        </a>
        <div class="bordergS bg-white br-20 px-md-4 py-md-3 p-2 position-relative pamentCard show">
            <div class="row w-100 mx-0">
                <div class="col-md-8">
                    <div class="col-12 mb-3">
                        <h6 class="mb-0 darkblueColor f-16 semibold">{{$t('paymentMethod.paymentMethodcontent2')}} </h6>
                    </div>
                    <div class="singleLoding" v-if="store.singleLoading">
                        <Loader></Loader>
                    </div>
                    <div class="savedCard mb-4 position-relative" v-else-if="store.subscriptionCards && store.subscriptionCards.length">
                        <label class="cutomChechBox" :class="[{'border-secondary':card.id==cardCheck},{'default':card.default}]" v-for="card,key in store.subscriptionCards" :key="key" :for="'pay'+key" @click="pamentMethod=false">
                            <div class="d-flex align-items-center justify-content-between minMid flex-wrap">
                                <div class="d-flex align-items-start mobFlex flex-column">
                                    <div class="d-flex align-items-center">
                                        <v-lazy-image class="me-2" width="60" height="25" :src="'/assets/images/card-icons/'+card.scheme.toLowerCase()+'.svg'" :alt="$t('paymentMethod.paymentMethodcontent3')" />
                                        <span class="medium f-16 capitalize">{{card.scheme.toLowerCase()}} XXXX {{card.last4}}</span>
                                        <span :title="$t('paymentMethod.paymentMethodcontent28')" v-if="card.default" class="status bg-secondary text-white mx-3 br-10 px-2">{{$t('subscription_plan.subscriptioncontent59')}}</span>
                                    </div>
                                    <div class="d-flex align-items-center mobFlex ps-2 mt-2">
                                        <span class="medium gray f-14 mobSmall me-3">{{$t('subscription_plan.subscriptioncontent61')}} {{card.expiryMonth || ''}}/{{card.expiryYear || ''}}</span>
                                    </div>
                                </div>
                                <div>
                                    <a :title="$t('paymentMethod.paymentMethodcontent29')" href="javascript:void(0)" class="secondary d-flex" v-if="(card.id==cardCheck) && !(card.default) && !payment"><vue-feather size="19" type="trash-2" @click="confirmRemoveCard=true;removeCardID=card.id"></vue-feather></a>
                                </div>
                                <div class="d-flex align-items-center showAfterCheck" v-if="(card.id==cardCheck) && (payment) && !$parent.filterStatus()">
                                    <div class="form-group mb-0">
                                        <button class="button fillBtn f-14 py-2" @click="packageCardPayment(card.id)">{{$t('subscription_plan.subscriptioncontent60')}} {{payment?('$'+payment.toFixed(2)):''}}</button>
                                    </div>
                                </div>
                                
                            </div>
                            <input type="radio" v-model="cardCheck" :value="card.id" :id="'pay'+key"/>
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="savedCard mb-4 position-relative nodata text-center" v-else>
                        <p class="midgray">{{$t('paymentMethod.paymentMethodcontent4')}}</p>
                    </div>
                    <div class="col-12 mb-3 cardAccordian customAccordianLink" :class="pamentMethod?'active':''">
                        <!-- v-if="payment" -->
                        <a class="flex-between p-3" href="javascript:void(0)" @click="pamentMethod = !pamentMethod;cardCheck=''">
                            <div class="d-flex align-items-center">
                                <span class="me-1 payMode"><v-lazy-image class="d-block" width="22" height="22" src="/assets/images/credit-card.png" :alt="$t('paymentMethod.paymentMethodcontent5')" /></span>
                                <h6 class="mb-0 darkblueColor f-16 semibold">{{$t('paymentMethod.paymentMethodcontent6')}} </h6>
                            </div>
                            <vue-feather size="22" class="dynamicIcon" :type="pamentMethod?'minus':'plus'"></vue-feather>
                        </a>
                        <!-- :class="pamentMethod?'show':''" -->
                        <div class="row my-2 mx-md-0 mobDirection" :class="pamentMethod?'':'d-none'">
                            <form id="payment-form">
                                <div class="input-container mb-3" v-if="payment && !$parent.filterStatus()">
                                    <div class="icon-container-static">
                                        <v-lazy-image src="/assets/images/card-icons/user.svg" :alt="$t('paymentMethod.paymentMethodcontent7') "/>
                                    </div>
                                    <div class="form-group w-100">
                                        <input class="form-control padStart" type="text" v-model="cardHolderName" :placeholder="$t('subscription_plan.subscriptioncontent62')" />
                                    </div>
                                </div>
                                <div class="input-container card-number mb-3">
                                    <div class="icon-container">
                                        <v-lazy-image id="icon-card-number" src="/assets/images/card-icons/card.svg" :alt="$t('paymentMethod.paymentMethodcontent8') "/>
                                    </div>
                                    <div class="card-number-frame"></div>
                                    <div class="icon-container payment-method">
                                        <img id="logo-payment-method" />
                                    </div>
                                    <div class="icon-container error">
                                        <v-lazy-image id="icon-card-number-error" src="/assets/images/card-icons/error.svg" :alt="$t('paymentMethod.paymentMethodcontent9')"/>
                                    </div>
                                </div>
                                <div class="date-and-code mb-3">
                                    <div>
                                        <div class="input-container expiry-date">
                                            <div class="icon-container">
                                                <v-lazy-image id="icon-expiry-date" src="/assets/images/card-icons/exp-date.svg" :alt="$t('paymentMethod.paymentMethodcontent10')"/>
                                            </div>
                                            <div class="expiry-date-frame"></div>
                                            <div class="icon-container error">
                                                <v-lazy-image id="icon-expiry-date-error" src="/assets/images/card-icons/error.svg"/>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div>
                                    <div class="input-container cvv">
                                        <div class="icon-container">
                                            <v-lazy-image id="icon-cvv" src="/assets/images/card-icons/cvv.svg" :alt="$t('paymentMethod.paymentMethodcontent11')" />
                                        </div>
                                        <div class="cvv-frame"></div>
                                        <div class="icon-container error">
                                            <v-lazy-image id="icon-cvv-error" src="/assets/images/card-icons/error.svg" />
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="mb-3" v-if="payment && !$parent.filterStatus()">
                                    <label class="cutomChechBox">
                                        <span class="f-14 midgray">{{$t('paymentMethod.paymentMethodcontent12')}} </span>
                                        <input type="checkbox" value="" id="privacyPolicy" v-model="privacyPolicy"/>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <button v-if="payment && !$parent.filterStatus()" id="pay-button" disabled :class="[{disabled:!privacyPolicy}]">{{$t('paymentMethod.paymentMethodcontent13')}}  {{payment?('$'+payment.toFixed(2)):''}}</button>
                                <button v-else id="pay-button" disabled>{{$t('paymentMethod.paymentMethodcontent14')}} </button>
                                <div>
                                    <span class="error-message error-message__card-number"></span>
                                    <span class="error-message error-message__expiry-date"></span>
                                    <span class="error-message error-message__cvv"></span>
                                </div>
                                <p class="d-flex align-items-center justify-content-center f-14">{{$t('paymentMethod.paymentMethodcontent15')}}  <v-lazy-image class="ms-2" src="/assets/images/chechOutCom.png" width="126" height="20" :alt="$t('paymentMethod.paymentMethodcontent16')" /></p>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div v-if="payment && !$parent.filterStatus()">
                        <div class="summaryPlan">
                            <p class="f-22">{{$t('paymentMethod.paymentMethodcontent17')}} </p>
                            <p class="mb-1 f-15">{{$t('paymentMethod.paymentMethodcontent18')}} 
                                <b> {{copyTrade}} {{$t('paymentMethod.paymentMethodcontent19')}} {{selectedPlanStep.durationInMonths}}
                                    {{$t('paymentMethod.paymentMethodcontent20')}} {{today()}}
                            </b></p>
                            <p class="f-13 mb-1">{{$t('paymentMethod.pm_1')}}{{(payment || 0).toFixed(2)}} {{$t('paymentMethod.pm_2')}} {{nextRecurring(selectedPlanStep.durationInMonths)}} {{$t('paymentMethod.pm_3')}}</p>
                            <p class="f-13 textcolor">{{$t('paymentMethod.paymentMethodcontent21')}} </p>
                            <div class="d-flex br-8 justify-content-between align-items-center recurring bg-lightprimary">
                                <h6 class="mb-0 darkblueColor f-14 semibold me-3">{{$t('paymentMethod.paymentMethodcontent23')}} </h6>
                                <h6 class="mb-0 f-22 semibold secondary">${{(payment || 0).toFixed(2)}}</h6>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <v-lazy-image height="190" class="w-75 d-block mx-auto" src="/assets/images/credit-card-animated.gif" :alt="$t('paymentMethod.paymentMethodcontent22')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal show fade" style="display: block;" v-if="confirmRemoveCard">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">{{$t('paymentMethod.paymentMethodcontent24')}} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="confirmRemoveCard=false"></button>
                </div>
                <div class="modal-body p-4 border-bottom text-center">
                    <p class="mb-0">{{$t('paymentMethod.paymentMethodcontent25')}} </p>
                </div>
                <div class="modal-footer border-0 justify-content-end">
                    <a href="javascript:void(0)" class="button border-button rounded border-button zulu_btn d-flex" data-bs-dismiss="modal" aria-label="Close" @click="confirmRemoveCard=false">{{$t('paymentMethod.paymentMethodcontent26')}} </a>
                    <a href="javascript:void(0)" class="button fillBtn zulu_btn d-flex" @click="removeCard(removeCardID);confirmRemoveCard=false">{{$t('paymentMethod.paymentMethodcontent27')}} </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import moment from "moment";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                pamentMethod:false,
                cardHolderName: '',
                // cvvNumber: {},
                cardCheck: '',
                privacyPolicy: false,
                appType: process.env.VUE_APP_TYPE,
                confirmRemoveCard: false,
                removeCardID: '',
            };
        },
        props:{
            payment: {
                default: ''
            },
            selectedPlanStep: {
                default: {}
            },
            copyTrade: {
                default: 1
            }
        },
        methods:{
            // clearCVV(vModel){
            //     this.store.subscriptionCards.forEach(element => {
            //         if(element.id != vModel){
            //             this.cvvNumber[vModel] = ''
            //         }
            //     });
            // },
            packageCardPayment(id){
                this.store.subscriptionPayment({id:id,amount:this.payment*100,currency:"USD"},true).then((res)=>{
                    if(res.links && res.links.redirect && res.links.redirect.href){
                        if(this.appType == 'live'){
                            window.location.replace(res.links.redirect.href)
                        }else{
                            window.open(res.links.redirect.href,'_blank')
                        }
                    }
                })
            },
            packagePayment(token){
                this.store.subscriptionPayment({token:token,amount:this.payment*100,currency:"USD"},true).then(res=>{
                    if(res.links && res.links.redirect && res.links.redirect.href){
                        window.location.replace(res.links.redirect.href)
                    }
                })
            },
            addCard(token){
                let payload = {
                    "token" : token,
                    "customer" : {
                        "defaultInstrument" : true
                    }
                }
                this.store.addPaymentMethod(payload,true).then((res)=>{
                    if(res){
                        this.store.subscriptionCardList({},true)
                        this.pamentMethod = false
                        this.initlization();
                    }
                })
            },
            removeCard(id){
                this.store.deletePaymentMethod({},true,id).then((res)=>{
                    if(res){
                        this.removeCardID = ''
                        this.confirmRemoveCard = false
                    }
                })
            },
            onCardTokenized(event) {
                if(!this.$parent.filterStatus() && this.$parent.planChooseORnot() && this.payment > 0){
                    this.packagePayment(event.token)
                } else {
                    this.addCard(event.token)
                }
            },
            initlization(){
                window.Frames.init({
                    publicKey: this.static_vars.CHECKOUT_PUBLIC_API_KEY,
                    // modes: [ window.Frames.modes.DISABLE_COPY_PASTE ],
                    style: {
                        base: {
                            color: '#ff7200',
                        }
                    },
                });
            },
            nextRecurring(month){
                let d = moment().add(month,'months')
                return d.format('Do MMMM YYYY')
            },
            today(){
                return moment().format('Do MMMM YYYY')
            },
        },
        mounted() {
            if(this.store.userDetail && this.store.userDetail.firstName && this.store.userDetail.lastName){
                this.cardHolderName = this.store.userDetail.firstName + ' ' + this.store.userDetail.lastName
            }
            this.initlization();
            var payButton = document.getElementById("pay-button");
            var form = document.getElementById("payment-form");
            var logos = generateLogos();
            function generateLogos() {
                var logos = {};
                logos["card-number"] = {
                    src: "card",
                    alt: "card number logo",
                };
                logos["expiry-date"] = {
                    src: "exp-date",
                    alt: "expiry date logo",
                };
                logos["cvv"] = {
                    src: "cvv",
                    alt: "cvv logo",
                };
                return logos;
            }
            var errors = {};
            errors["card-number"] = "Please enter a valid card number";
            errors["expiry-date"] = "Please enter a valid expiry date";
            errors["cvv"] = "Please enter a valid cvv code";

            window.Frames.addEventHandler(window.Frames.Events.FRAME_VALIDATION_CHANGED,onValidationChanged);
            function onValidationChanged(event) {
                var e = event.element;
                if (event.isValid || event.isEmpty) {
                    if (e === "card-number" && !event.isEmpty) {
                        showPaymentMethodIcon();
                    }
                    setDefaultIcon(e);
                    clearErrorIcon(e);
                    clearErrorMessage(e);
                } else {
                    if (e === "card-number") {
                        clearPaymentMethodIcon();
                    }
                    setDefaultErrorIcon(e);
                    setErrorIcon(e);
                    setErrorMessage(e);
                }
            }
            function clearErrorMessage(el) {
                var selector = ".error-message__" + el;
                var message = document.querySelector(selector);
                message.textContent = "";
            }
            function clearErrorIcon(el) {
                var logo = document.getElementById("icon-" + el + "-error");
                logo.style.removeProperty("display");
            }
            function showPaymentMethodIcon(parent, pm) {
                if (parent) parent.classList.add("show");

                var logo = document.getElementById("logo-payment-method");
                if (pm) {
                    var name = pm.toLowerCase();
                    logo.setAttribute("src", "/assets/images/card-icons/" + name + ".svg");
                    logo.setAttribute("alt", pm || "payment method");
                }
                logo.style.removeProperty("display");
            }
            function clearPaymentMethodIcon(parent) {
                if (parent) parent.classList.remove("show");
                var logo = document.getElementById("logo-payment-method");
                logo.style.setProperty("display", "none");
            }
            function setErrorMessage(el) {
                var selector = ".error-message__" + el;
                var message = document.querySelector(selector);
                message.textContent = errors[el];
            }
            function setDefaultIcon(el) {
                var selector = "icon-" + el;
                var logo = document.getElementById(selector);
                logo.setAttribute("src", "/assets/images/card-icons/" + logos[el].src + ".svg");
                logo.setAttribute("alt", logos[el].alt);
            }
            function setDefaultErrorIcon(el) {
                var selector = "icon-" + el;
                var logo = document.getElementById(selector);
                logo.setAttribute("src", "/assets/images/card-icons/" + logos[el].src + "-error.svg");
                logo.setAttribute("alt", logos[el].alt);
            }
            function setErrorIcon(el) {
                var logo = document.getElementById("icon-" + el + "-error");
                logo.style.setProperty("display", "block");
            }
            window.Frames.addEventHandler(window.Frames.Events.CARD_VALIDATION_CHANGED,cardValidationChanged);
            function cardValidationChanged() {
                payButton.disabled = !window.Frames.isCardValid();
            }
            window.Frames.addEventHandler(window.Frames.Events.CARD_TOKENIZATION_FAILED,onCardTokenizationFailed);
            function onCardTokenizationFailed(error) {
                console.log("CARD_TOKENIZATION_FAILED: %o", error);
                window.Frames.enableSubmitForm();
            }
            window.Frames.addEventHandler(window.Frames.Events.CARD_TOKENIZED, this.onCardTokenized);
            window.Frames.addEventHandler(window.Frames.Events.PAYMENT_METHOD_CHANGED,paymentMethodChanged);
            function paymentMethodChanged(event) {
                var pm = event.paymentMethod;
                    let container = document.querySelector(".icon-container.payment-method");
                    if (!pm) {
                        clearPaymentMethodIcon(container);
                    } else {
                        clearErrorIcon("card-number");
                        showPaymentMethodIcon(container, pm);
                    }
                }
            form.addEventListener("submit", onSubmit);
            function onSubmit(event) {
                event.preventDefault();
                window.Frames.submitCard();
            }
        },
    };
</script>